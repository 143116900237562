import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socket = io('http://localhost:4000');

const App = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState('');
  const [userContact, setUserContact] = useState('');
  const [friendContact, setFriendContact] = useState('');
  const [currentChat, setCurrentChat] = useState('');

  useEffect(() => {
    socket.on('receiveMessage', (data) => {
      console.log('Message received from server:', data);
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, []);

  useEffect(() => {
    if (userContact) {
      socket.emit('register', userContact);
      console.log(`User registered with contact: ${userContact}`);
    }
  }, [userContact]);

  const sendMessage = () => {
    const newMessage = { user, userContact, friendContact: currentChat, message };
    console.log('Sending message:', newMessage);
    socket.emit('sendMessage', newMessage);
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setMessage('');
  };

  const startChat = (contact) => {
    console.log('Starting chat with contact:', contact);
    setCurrentChat(contact);
  };

  return (
    <div className="App">
      <div>
        <input
          type="text"
          placeholder="Your name"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
        <input
          type="text"
          placeholder="Your contact number"
          value={userContact}
          onChange={(e) => setUserContact(e.target.value)}
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="Friend's contact number"
          value={friendContact}
          onChange={(e) => setFriendContact(e.target.value)}
        />
        <button onClick={() => startChat(friendContact)}>Start Chat</button>
      </div>
      {currentChat && (
        <div>
          <h2>Chat with {currentChat}</h2>
          <div className="chat-box">
            {messages
              .filter((msg) => (msg.userContact === userContact && msg.friendContact === currentChat) || (msg.userContact === currentChat && msg.friendContact === userContact))
              .map((msg, index) => (
                <div key={index}>
                  <strong>{msg.user}:</strong> {msg.message}
                </div>
              ))}
          </div>
          <input
            type="text"
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={sendMessage}>Send</button>
        </div>
      )}
    </div>
  );
};

export default App;
